const translations = {
  add: {
    subtitle: "Preset goal library",
    title: "Set a goal"
  },
  archive: {
    subtitle: "Your archived goals",
    title: "Archive"
  },
  empty: {
    ctas: {
      set: {
        subtitle: "Choose from our collection of expert-reviewed goals and add them to your Care Plan.",
        title: "Start building independently"
      },
      support: {
        subtitle:
          "Receive personalized care planning from your Care Partner, {expertName}, by scheduling an initial session.",
        title: "Get Care Partner support"
      }
    },
    subtitle: "Start with a step-by-step roadmap that guides you with clarity and confidence.",
    title: "Your Care Plan is empty"
  }
};

export default translations;
