/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-lines */
const errors = {
  admin: {
    firstName: {
      blank: "A first name is required"
    },
    lastName: {
      blank: "A last name is required"
    },
    timezone: {
      blank: "A timezone is required"
    },
    timezoneString: {
      blank: "A timezone is required"
    },
    zoomId: {
      blank: "A zoom id is required"
    }
  },

  appEvent: {
    actionKind: {
      inclusion: "An invalid kind was provided for the app event"
    }
  },
  article: {
    base: {
      creatorMustHaveZoomAccess: "You must connect your Zoom account in order to create a live event"
    },
    blurb: {
      blank: "A blurb is required",
      tooLong: "The maximum length for the blurb is 300 characters"
    },
    caregiverFirstName: {
      blank: "A first name is required for the caregiver"
    },
    contentType: {
      blank: "A content type is required"
    },
    countryCodes: {
      blank: "At least one country is required",
      globalError: "Please select either all countries or individual countries"
    },
    focusAreas: {
      blank: "A focus area is required"
    },
    photoBlobId: {
      blank: "An image is required. If your article is a video, this image will be used as the preview.",
      invalid: "Please select a jpg, gif, png or tiff file"
    },
    quote: {
      blank: "A block quote is required"
    },
    sources: {
      label: {
        blank: "A label is required"
      },
      value: {
        blank: "A source is required",
        includesHttp: "Link must include http or https"
      }
    },
    text: {
      blank: "Content is required for text articles"
    },
    title: {
      blank: "A title is required"
    },
    transcript: {
      blank: "A transcript is required for video articles"
    },
    videoBlobId: {
      blank: "A video is required for video articles",
      invalid: "Please select an mp4 file"
    },
    webinarCompleted: {
      blank: "You must select a value for whether or not the live event has been completed"
    },
    webinarDuration: {
      blank: "A duration is required for the live event",
      number: "Live event duration must be a number",
      tooSmall: "Live event duration must be greater than zero"
    },
    webinarStartTime: {
      blank: "A start time is required for the live event",
      startTimeInPast: "The start time must be in the future"
    }
  },
  articleRecommend: {
    recommended: {
      inclusion: "Article must be recommended or not recommended"
    }
  },
  articleSave: {
    saved: {
      inclusion: "Article must be saved or unsaved"
    }
  },
  articleShare: {
    actor: {
      blank: "An actor is required"
    },
    article: {
      blank: "An article is required"
    }
  },
  assessmentStep: {
    skipReason: {
      inclusion: "An invalid skip reason was provided"
    }
  },
  automation: {
    parentBlock: {
      blank: "We're having trouble deleting this block, let the dev team know"
    },
    title: {
      blank: "A title is required",
      taken: "The title must be unique"
    }
  },
  automationActionBlock: {
    archiveTag: {
      blank: "A tag to archive is required"
    },
    articleId: {
      blank: "An article is required"
    },
    carePlanTaskTemplateId: {
      blank: "A task template is required"
    },
    duration: {
      blank: "A length of time is required",
      greaterThan: "The time must be >= 0"
    },
    durationUnits: {
      blank: "A unit for the time period is required",
      inclusion: "A unit for the time period is required"
    },
    goalTemplateId: {
      blank: "A goal is required"
    },
    kind: {
      blank: "An action type is required",
      inclusion: "The type of action must be in the list"
    },
    messageContent: {
      blank: "Message content is required"
    },
    tag: {
      blank: "A tag is required"
    },
    taskClientVisible: {
      inclusion: "Task client visible must be true or false"
    },
    taskKind: {
      inclusion: "A type of task is required"
    },
    taskTitle: {
      blank: "A title is required for the task"
    }
  },
  automationCondition: {
    careProfileField: {
      blank: "A care profile question is required"
    },
    careProfileValues: {
      blank: "A care proflie answer is required"
    },
    duration: {
      blank: "A length of time is required"
    },
    durationUnits: {
      blank: "A unit of time is required"
    },
    kind: {
      blank: "A condition type is required"
    }
  },
  automationConditionGroup: {
    operation: {
      blank: "An operation type is required",
      inclusion: "The operation must be either AND or OR"
    }
  },
  automationTimingBlock: {
    duration: {
      blank: "A duration is required",
      greater_than: "Duration must be > 0",
      number: "Duration should be a number"
    },
    durationUnits: {
      blank: "Units for the duration are required",
      inclusion: "Duration units must be in the list"
    }
  },
  automationTriggerBlock: {
    careProfileField: {
      blank: "A care profile field is required",
      inclusion: "The care profile field must be in the list"
    },
    consultReportKind: {
      blank: "A session type is required"
    },
    duration: {
      blank: "A length of time is required",
      greaterThan: "Must be >= 0"
    },
    durationUnits: {
      blank: "Duration units are required",
      inclusion: "Duration units must be in the list"
    },
    frequency: {
      inclusion: "Frequency must be in the list"
    },
    kind: {
      blank: "A type of trigger is required",
      inclusion: "Type must be in the list of options"
    },
    signedDocumentKind: {
      blank: "A type of signed document is required",
      inclusion: "Signed document type must be in the list"
    },
    tag: {
      blank: "A tag is required"
    },
    trackerType: {
      blank: "A tracker type is required",
      inclusion: "The type of tracker must be in the list of options"
    },
    userType: {
      blank: "A type of user is required",
      inclusion: "The type of user must be in the list"
    }
  },
  banner: {
    base: {
      alreadyDismissed: "This banner was already dismissed"
    }
  },
  carePlan: {
    base: {
      notPublishable: "The Care Plan is not publishable"
    },
    description: {
      blank: "A summary is required"
    },
    plan: {
      blank: "A schedule of support is required"
    },
    stage: {
      blank: "A stage is required"
    }
  },
  carePlanActionItem: {
    actor: {
      cannotUpdateGoalUntilEditModeComplete: "Your Care Partner is working on this goal"
    },
    assigneeIds: {
      min: "An owner is required"
    },
    base: {
      actionItemIdsIncorrect: "The action items being reordered are not all present, try refreshing"
    },
    dueDate: {
      mustNotBeInThePast: "Must not be in the past"
    },
    focusArea: {
      blank: "A focus area is required"
    },
    goal: {
      mustMatchJourney: "The goal must be in the journey goals"
    },
    goalId: {
      blank: "A goal is required"
    },
    title: {
      blank: "A title is required"
    }
  },
  carePlanTaskTemplate: {
    body: {
      blank: "A body is required"
    },
    focusAreas: {
      blank: "A focus area is required"
    },
    title: {
      blank: "A title is required"
    },
    userType: {
      blank: "A user type is required"
    }
  },
  careProfile: {
    age: {
      blank: "An age is required"
    },
    email: {
      invalid: "This email is invalid"
    },
    exactAge: {
      integer: "Age must be a whole number",
      tooLarge: "Age must be < 150",
      tooSmall: "Age must be >= 0"
    },
    focusAreas: {
      blank: "A focus area is required",
      mustHaveTwo: "Please select exactly two ways Grayce can help"
    },
    guidanceType: {
      blank: "A type of guidance is required"
    },
    languageCode: {
      blank: "A language selection is required"
    },
    languageInterpretationNeeded: {
      blank: "A selection is required for whether you need language interpretation"
    },
    location: {
      blank: "A location is required"
    },
    name: {
      blank: "A name is required"
    },
    nextConsult: {
      blank: "Please enter a next session date",
      mustNotBeInThePast: "Must not be in the past"
    },
    number: {
      invalid: "This phone number is invalid"
    },
    pastYearHospitalizations: {
      greaterThanOrEqualTo: "Must be >= 0",
      integer: "Must be a whole number",
      number: "Must be a whole number",
      tooLarge: "Must be < 500"
    },
    placeId: {
      blank: "Please enter a location",
      noResults: "No results. Search must be a specific postal code, neighborhood, or address."
    },
    relationship: {
      blank: "A relationship is required"
    },
    residenceType: {
      blank: "A residence type is required"
    },
    timezone: {
      blank: "A timezone is required"
    },
    typesOfSupportNeeded: {
      blank: "A type of Grayce support is required"
    },
    urgency: {
      blank: "Please select a value for urgency"
    },
    useCase: {
      blank: "Please select a use case"
    }
  },
  careTeamInvite: {
    base: {
      accepted: "This invite has already been accepted",
      alreadyRedeemed: "This invite has already been redeemed",
      canceled: "This invite has been canceled",
      expired: "This invite has expired, contact the inviter to resend it",
      invalidKind: "This invite is invalid, contact support@withgrayce.com",
      inviteAlreadyAccepted: "You have already accepted this invite, try refreshing.",
      inviteCanceled: "This invite is not longer available.",
      noToken: "This invite is invalid, contact support@withgrayce.com",
      recentlySent: "This invite has recently been sent, please try again later",
      revoked: "This invite has been revoked",
      userMustExist: "You are not able to accept the invite",
      userUnauthorized: "You must be logged in to accept this invite"
    }
  },
  chat: {
    base: {
      chatAlreadyExists: "A chat already exists for these members",
      mustBeOnJourney: "You must be on this journey to chat",
      ownerMustBeOnChat: "The account owner must be on the chat"
    }
  },
  client: {
    email: {
      email: "This email is invalid"
    },
    everBeenCaregiver: {
      blank: "A selection is required for whether you have ever been a caregiver"
    },
    placeId: {
      blank: "A location is required"
    },
    supportingAdultChildren: {
      greaterThanOrEqualTo: "Must be >= 0",
      integer: "Must be a whole number",
      number: "Must be a number"
    },
    supportingGrandparents: {
      greaterThanOrEqualTo: "Must be >= 0",
      integer: "Must be a whole number",
      number: "Must be a number"
    },
    supportingMinorChildren: {
      greaterThanOrEqualTo: "Must be >= 0",
      integer: "Must be a whole number",
      number: "Must be a number"
    },
    supportingOther: {
      greaterThanOrEqualTo: "Must be >= 0",
      integer: "Must be a whole number",
      number: "Must be a number"
    },
    supportingParents: {
      greaterThanOrEqualTo: "Must be >= 0",
      integer: "Must be a whole number",
      number: "Must be a number"
    },
    timezone: {
      blank: "A timezone is required"
    },
    useCasePreferences: {
      blank: "At least one use case is required",
      inclusion: "Use case options must be a valid use case"
    }
  },
  clientAccountUpdate: {
    email: {
      taken: "Sorry, that email is taken"
    },
    number: {
      invalid: "This phone number is invalid"
    }
  },
  clientPasswordUpdate: {
    passwordCurrent: {
      incorrect:
        "This doesn't match the password we have for your account. You may need to log out and reset your password."
    }
  },
  codeVerification: {
    base: {
      failed: "Something went wrong, please try again later",
      noPhoneNumber: "Your account does not have a phone number, if something went wrong contact support@withgrayce.com"
    },
    code: {
      blank: "A code is required",
      invalid: "The verification code did not match",
      mustBeSixDigits: "Enter the six digit code sent to your mobile device"
    }
  },
  consentForm: {
    attachmentBlobId: {
      blank: "An uploaded file is required",
      invalid: "Needs to be a PDF or an image"
    },
    documentFor: {
      blank: "The name of the person giving consent is required"
    },
    medium: {
      blank: "A type is required"
    },
    recipientEmailAddress: {
      blank: "A recipient email is required"
    },
    recipientName: {
      blank: "A recipient name is required"
    }
  },
  consult: {
    base: {
      alreadyCanceled: "This session is already canceled",
      availabilityRequired: "Please select an availability before scheduling",
      badTime: "This time is unavailable, please select another time",
      conflict: "This time is unavailable, please select another time",
      icrAttached: "This session already has a published initial consult report and cannot be moved",
      noExpertAccess: "Care Partner access is required to schedule a session",
      notAssigned: "Care Partner must be assigned to the journey",
      startTimeInPast: "This session has already started",
      unauthorized: "You are not allowed to do this",
      usedByTeamMember: "One of the session invites is already on this journey. Remove it and try again."
    },
    contactMethod: {
      blank: "A contact method is required"
    },
    duration: {
      blank: "A duration is required"
    },
    expertType: {
      blank: "A Care Partner type is required"
    },
    invitedClientIds: {
      empty: "An invitee is requred",
      invalid: "A selected invitee is invalid"
    },
    invitee: "An invitee is required",
    newJourneyId: {
      blank: "A journey is required"
    },
    phoneNumber: {
      blank: "A phone number is required",
      number: "Sorry, that is not a valid phone number",
      taken: "Sorry, that phone number is already in use"
    }
  },
  consultCancel: {
    base: {
      alreadyCanceled: "This session is already canceled",
      startTimeInPast: "This session has already started"
    },
    reason: {
      blank: "A reason for canceling is required"
    }
  },
  consultReport: {
    base: {
      notPublishable: "This session report is not publishable"
    },
    clientAttendees: {
      blank: "Member attendees are required"
    },
    duration: {
      blank: "A duration is required",
      number: "Must be a number",
      tooSmall: "Must be greater than 0"
    },
    expertAttendeeId: {
      blank: "A Care Partner attendee is required"
    },
    format: {
      blank: "A format is required"
    },
    nextConsult: {
      mustNotBeInThePast: "Must not be in the past"
    },
    stage: {
      blank: "A care journey stage is required"
    },
    summary: {
      blank: "A summary is required"
    }
  },
  consultReportDiscussionItem: {
    focusAreas: {
      blank: "At least one focus area is required"
    },
    nextSteps: {
      blank: "Next steps are required"
    },
    notes: {
      blank: "Notes are required"
    },
    title: {
      blank: "A title is required"
    }
  },
  contentConditions: {
    careProfileField: {
      required: "An answer is required"
    },
    careProfileValue: {
      required: "A question is required"
    },
    concern: {
      required: "A concern is required"
    },
    kind: {
      required: "A criteria or question is required"
    },
    needFromGrayce: {
      required: "A need is required"
    },
    providerEmotionalState: {
      required: "An emotional state is required"
    },
    providerFeelsSupported: {
      required: "A supported value is required"
    },
    relationship: {
      required: "A relationship is required"
    },
    source: {
      required: "A source is required"
    },
    stage: {
      required: "A stage value is required"
    }
  },
  default: "Sorry, something has gone wrong.",
  diagnosis: {
    conditionOther: {
      blank: "A condition is required"
    },
    medicalConditionId: {
      blank: "A condition is required"
    }
  },
  eligibility: {
    employerId: {
      blank: "An employer is required",
      signupsNotAllowed:
        "Signups are not allowed for this employer. This may be because they do not have a current contract, their contract has not yet started, or they have not renewed their contract."
    },
    expertId: {
      blank: "A Care Partner is required"
    },
    journeyIds: {
      blank: "A journey is required"
    },
    notes: {
      blank: "Notes are required"
    }
  },
  eligibilityFile: {
    base: {
      includesErrors: "This eligibility file includes errors",
      noDryRun: "This eligibility file has not gone through a dry run",
      notMostRecent: "This is not the most recent eligibility file"
    },
    blobId: {
      invalid: "Please select a CSV file"
    }
  },
  email: {
    base: {
      invalidToken: "This token is invalid",
      missingToken: "Please provide a token",
      recentlySent: "This email has recently been sent, please try again later",
      tokenAlreadyUsed: "This token is invalid",
      tokenExpired: "This token is expired, please request a new email"
    }
  },
  employer: {
    base: {
      hasActiveEmployees: "This employer must have no active employees before deleting"
    },
    eligibilityMethod: {
      blank: "An eligibility method is required"
    },
    emailDomain: {
      blank: "A domain is required",
      format: "Domains need to include only the part after the @ sign, like example.com"
    },
    employeeCount: {
      number: "Must be a number",
      tooSmall: "Must be zero or more"
    },
    employeeCountry: {
      blank: "A country is required",
      number: "Must be a number",
      tooSmall: "Must be zero or more"
    },
    employeesPurchased: {
      blank: "A number of purchased employees is required (but zero is ok)",
      number: "Must be a number",
      tooSmall: "Must be zero or more"
    },
    enrollmentCode: {
      blank: "An enrollment code is required"
    },
    expectedUtilization: {
      number: "Must be a number",
      tooSmall: "Must be zero or more"
    },
    minutes: {
      blank: "A number of minutes is required",
      number: "Must be a number",
      tooSmall: "Minutes must be greater than or equal to zero"
    },
    name: {
      blank: "A name is required"
    },
    website: { formatting: "Link must be a valid URL" }
  },
  employerBenefit: {
    categories: {
      blank: "At least one category is required",
      invalid: "Please select a jpg, gif, png or tiff file"
    },
    categoryOther: {
      blank: "An other category is required"
    },
    cost: {
      blank: "A cost is required"
    },
    countryCodes: {
      blank: "At least one country is required",
      globalError: "Please select either all countries or individual countries"
    },
    description: {
      blank: "A description is required"
    },
    directUploadBlobIds: {
      invalid: "Please select a PDF file"
    },
    emailLink: {
      blank: "An email link is required",
      invalid: "The email link must be an https URL or email"
    },
    hoursOfOperation: {
      blank: "Hours of operation is required"
    },
    link: {
      blank: "A link url is required if the title is present",
      formatting: "Link must be a valid URL"
    },
    links: {
      blank: "At least one link is required"
    },
    phoneNumber: {
      invalid: "This phone number is invalid"
    },
    phoneNumberLink: {
      blank: "A phone number link is required",
      invalid: "The phone number link must be an https URL or phone number"
    },
    photoBlobId: {
      blank: "A photo is required",
      invalid: "Please select a jpg, gif, png or tiff file"
    },
    vendorName: {
      blank: "A vendor name is required"
    }
  },
  employerContract: {
    base: {
      cannotModifyWhenInEffect:
        "Once a contract is in effect, you cannot edit it directly. Create contract modifications instead."
    },
    billableCalculation: {
      blank: "A billable calculation method is required"
    },
    billableCap: {
      integer: "A whole number of billable seats is required",
      number: "Must be a number",
      tooSmall: "Must be zero or more"
    },
    employeesPurchased: {
      blank: "A number of employees purchased is required",
      integer: "A whole number of employees is required",
      number: "Employees purchased must be a number",
      tooSmall: "Select a number of employees purchased >= zero"
    },
    hasCarePlanning: {
      blank: "A selection for the care planning package is required",
      cannotBeRemoved: "When the contract has already gone into effect you may not remove the care planning package"
    },
    hasUnlimitedMinutes: {
      blank: "A selection for unlimited time is required",
      cannotBeRemoved: "When the contract has already gone into effect you may not remove unlimited time",
      mustNotIncludeOtherBenefits: "You cannot have unlimited time and also a care planning package or limited time"
    },
    minutes: {
      blank: "A number of minutes is required",
      cannotBeReduced: "When the contract has already gone into effect you may not reduce the number of minutes",
      integer: "A whole number of minutes is required",
      number: "Minutes must be a number",
      tooSmall: "Select a number of minutes >= zero"
    },
    startDate: {
      beforeExistingEnd:
        "When making a new contract, the start date must be after the end date for all existing contracts with the employer",
      blank: "A start date is required"
    },
    years: {
      blank: "A number of years is required",
      integer: "A whole number of years is required",
      number: "Years must be a number",
      tooLarge: "The number of years cannot be greater than 10",
      tooSmall: "Select a number of years >= zero"
    }
  },
  employerContractModification: {
    base: {
      noChangesSelected: "You must select at least one part of the contract to update"
    },
    billableCalculation: {
      blank: "A billable calculation method is required"
    },
    billableCap: {
      blank: "A max number of billable members is required",
      integer: "A whole number of billable seats is required",
      number: "Must be a number",
      tooSmall: "Must be zero or more"
    },
    employeesPurchased: {
      blank: "A number of employees purchased is required",
      integer: "A whole number of employees is required",
      number: "Employees purchased must be a number",
      tooSmall: "Select a number of employees purchased >= zero"
    },
    hasCarePlanning: {
      blank: "A selection for the care planning package is required",
      cannotBeRemoved:
        "You cannot create a retroactive contact modification that removes the care planning package unless it adds unlimited minutes"
    },
    hasUnlimitedMinutes: {
      blank: "A selection for unlimited time is required",
      cannotBeRemoved: "You cannot create a retroactive contract modification that removes unlimited minutes",
      mustNotIncludeOtherBenefits: "You cannot have unlimited time and also a care planning package or limited time"
    },
    minutes: {
      blank: "A number of minutes is required",
      cannotBeReduced:
        "You cannot create a retroactive contract modification that reduces the number of minutes unless it adds unlimited minutes",
      integer: "A whole number of minutes is required",
      number: "Minutes must be a number",
      tooSmall: "Select a number of minutes >= zero"
    },
    startDate: {
      blank: "A start date is required",
      mustBeWithinContract: "The start date must be within the time period of the contract"
    }
  },
  errorFallback: "Sorry, something has gone wrong",
  expert: {
    base: {
      tooManySpecialties: "The number of specialties cannot be greater than 10"
    },
    bio: {
      blank: "A bio is required"
    },
    coversAsia: {
      blank: "A selection for Asian coverage is required",
      inclusion: "Covers Asia must be true or false"
    },
    coversEurope: {
      blank: "A selection for Europe coverage is required",
      inclusion: "Covers Europe must be true or false"
    },
    coversUsEast: {
      blank: "A selection for US Eastern coverage is required",
      inclusion: "Covers US East must be true or false"
    },
    coversUsWest: {
      blank: "A selection for US Western coverage is required",
      inclusion: "Covers US West must be true or false"
    },
    email: {
      blank: "An email is required",
      email: "Email address format is required",
      taken: "Sorry, that email is already in use"
    },
    expert: {
      activeRoles: "You can't delete a Care Partner with active roles"
    },
    expertArchived: {
      inclusion: "Care Partner archived must be true or false"
    },
    expertArchivedReason: {
      inclusion: "An invalid archive reason was provided"
    },
    firstName: {
      blank: "A first name is required"
    },
    journeyAssignmentWeight: {
      number: "Assignment weight must be a number",
      tooBig: "Weight must be less than or equal to 100",
      tooSmall: "Weight must not be negative"
    },
    lastName: {
      blank: "A last name is required"
    },
    maxConsultsPerDay: {
      integer: "Maximum daily sessions must be an integer",
      number: "Maximum daily sessions must be a number",
      tooSmall: "Maximum daily sessions must be >= 1"
    },
    otherSpecialties: {
      tooMany: "The number of specialties cannot be greater than 10"
    },
    photoBlobId: {
      blank: "A photo is required",
      invalid: "Please select a jpg, gif, png or tiff file"
    },
    specialtyAges: {
      invalid: "Invalid specialty ages provided"
    },
    specialtyConditions: {
      invalid: "Invalid specialty conditions provided"
    },
    specialtyOthers: {
      invalid: "Invalid specialties provided"
    },
    timezone: {
      blank: "A timezone is required"
    },
    timezoneString: {
      blank: "A timezone is required"
    },
    zoomId: {
      blank: "A zoom id is required"
    }
  },
  expertRole: {
    actor: {
      blank: "An actor is required"
    },
    assignExpert: {
      blank: "A Care Partner to assign is required"
    },
    expertRole: {
      cannotRemovePrimaryExpert: "Cannot remove primary Care Partner"
    }
  },
  fileResource: {
    base: {
      mustBeEitherAWorksheetOrAttachment: "Must not have an attachment when there is a worksheet",
      mustHaveARecord: "Must have an attachment, worksheet or a pdf type"
    },
    blobId: {
      blank: "A file is required",
      invalidType: "Please select a valid file type"
    },
    folderId: {
      mustMatchJourney: "The folder must be on the journey"
    },
    title: {
      blank: "A file name is required"
    }
  },
  findAnotherTime: {
    content: {
      blank: "Please describe what time works best for you"
    }
  },
  folder: {
    base: {
      mustHaveZeroFiles: "Please remove all the files before deleting the folder"
    },
    title: {
      blank: "A folder name is required",
      taken: "That folder name is already taken",
      tooLong: "The maximum length for the folder name is 30 characters"
    }
  },
  goal: {
    article: {
      alreadyExists: "This article has already been added to this goal",
      mustBeOnGoal: "This article has already been removed"
    },
    base: {
      actionItemIdsIncorrect: "The action items being reordered are not all present, try refreshing",
      goalAlreadyPublished: "This goal has already been published, try refreshing",
      goalIdsIncorrect: "The goals being reordered are not all present, try refreshing",
      taken:
        "This goal has already been added to your Care Plan. Your Care Partner may be working on publishing these changes."
    },
    employerBenefit: {
      alreadyExists: "This benefit has already been added to this goal",
      mustBeOnGoal: "This benefit cannot be removed"
    },
    title: {
      blank: "A value is required"
    },
    tool: {
      alreadyExists: "This tool has already been added to this goal"
    }
  },
  goalTemplate: {
    articleIds: {
      blank: "Articles are required"
    },
    carePlanTaskTemplateIds: {
      blank: "Task templates are required"
    },
    countryCodes: {
      blank: "At least one country is required",
      globalError: "Please select either all countries or individual countries"
    },
    focusAreas: {
      blank: "At least one focus area is required"
    },
    title: {
      blank: "A title is required",
      taken: "Title must be unique per journey"
    },
    tools: {
      blank: "Tools are required"
    }
  },
  handleQuery: {
    notFound: "Sorry, this information could not be found.",
    somethingWentWrong: "Something went wrong, we're sorry.",
    unauthorized: "You are not authorized to do this."
  },
  initialConsultReport: {
    base: {
      notPublishable: "This initial session report is not publishable."
    }
  },
  internalNote: {
    body: {
      blank: "A note is required"
    },
    kind: {
      inclusion: "An invalid internal note kind was provided"
    }
  },
  inviteAccountMembers: {
    base: {
      journeyNotOnboarded: "This journey has not completed onboarding yet.",
      journeyOwnerIneligible: "The journey's owner does not have any eligibile package grants."
    },
    email: {
      alreadyInvited: "This email is already invited",
      blank: "An email is required",
      email: "Needs to be an email address",
      usedByTeamMember: "Sorry, that email is already in use"
    },
    emailFormat: "Needs to be an email address",
    moreEmails: "You must add at least one email to invite"
  },
  inviteeRegistration: {
    base: {
      default: "Something went wrong with that link",
      invalidToken: "That didn't work. Try logging in",
      inviteAlreadyAccepted: "That link has already been used. Try logging in",
      inviteCanceled: "This invitation has been canceled. Try asking for another invitation",
      missingToken: "That didn't work. Try logging in",
      tokenAlreadyUsed: "That link has already been used. Try logging in",
      tokenExpired: "This invitation has expired. Try asking for another invitation",
      userExists: "This user already exists. Try logging in"
    },
    dateOfBirth: {
      blank: "A date of birth is required",
      correctFormat: "Please use this format: mm/dd/yyyy",
      invalid: "Please pick a valid date of birth",
      tooLarge: "Please pick a valid date of birth",
      tooSmall: "Grayce requires users to be at least 18 years old. Please pick a valid date of birth."
    },
    disclosureAgreement: {
      notAccepted: "Please accept the disclosure agreement"
    },
    email: {
      blank: "An email is required",
      email: "Needs to be an email address",
      notAnEmail: "Needs to be an email address",
      taken: "Sorry, that email is already in use"
    },
    firstName: {
      blank: "A first name is required"
    },
    lastName: {
      blank: "A last name is required"
    },
    password: {
      blank: "A password is required",
      invalid: "That password is not strong enough. See the requirements above.",
      weak: "Please enter a stronger password"
    },
    passwordConfirmation: {
      blank: "Please enter matching passwords",
      mismatch: "Please enter matching passwords"
    },
    termsAndConditions: {
      inclusion: "Please accept the terms and conditions",
      notAccepted: "Please accept the terms and conditions"
    },
    timezone: {
      blank: "A timezone is required"
    }
  },
  issueReport: {
    message: {
      blank: "A message is required"
    }
  },
  journeyExpertArchive: {
    other: {
      blank: "An explanation is required for other"
    },
    reason: {
      blank: "A reason is required"
    }
  },
  language: {},
  legalDocument: {
    status: {
      blank: "A status is required"
    },
    updateDate: {
      correctFormat: "Please use this format: mm/dd/yyyy",
      moreRecent: "Please use a more recent date"
    }
  },
  login: {
    base: {
      invalidLogin: "Email or password was invalid. Please try again.",
      noRoles: "Your account is inactive.",
      recaptchaFailed: "Sorry, that didn't work. Please try again."
    },
    email: {
      blank: "An email is required",
      email: "Needs to be an email address",
      notAnEmail: "Needs to be an email address"
    },
    password: {
      blank: "A password is required"
    }
  },
  medication: {
    drugId: {
      blank: "A drug is required"
    },
    kind: {
      blank: "A medication type is required"
    },
    name: {
      blank: "A name is required"
    }
  },
  message: {
    actor: {
      blank: "An actor is required"
    },
    attachments: {
      tooLarge: "One of the attachments is too large"
    },
    author: {
      blank: "An author is required"
    },
    base: {
      noExpertAccess: "Care Partner access is required for messaging"
    },
    body: {
      blank: "A body is required",
      invalidMessageGroup: "Something went wrong, please refresh and try again"
    }
  },
  messageTemplate: {
    categoryId: {
      blank: "A category is required"
    },
    content: {
      blank: "A message is required"
    },
    title: {
      blank: "A name is required",
      taken: "This title is already used"
    }
  },
  messageTemplateCategory: {
    base: {
      templatesAttached: "Remove all templates attached to the category before deleting it"
    },
    title: {
      blank: "A name is required"
    }
  },
  myStoryLead: {
    message: {
      blank: "A message is required"
    }
  },
  newJourney: {
    age: {
      blank: "An age is required"
    },
    countryCode: {
      blank: "A country code is required"
    },
    name: {
      blank: "A name is required"
    },
    placeId: {
      blank: "A location is required"
    }
  },
  onboard: {
    base: {
      alreadyOnboarded: "This journey is already onboarded, try refreshing.",
      notEligible: "Sorry, you are not eligible to do this."
    }
  },
  outOfOffice: {
    backupExpertId: {
      blank: "A backup Care Partner is required",
      mustNotBeOutOfOffice: "The backup Care Partner must not be out of office"
    },
    endTime: {
      blank: "End time is required",
      mustBeAfterStartTime: "The end time must not be before the start time",
      mustNotBeInThePast: "End time must not be in the past",
      tooFarFromStartTime: "The end time is too far from the start time"
    },
    expertId: {
      blank: "A Care Partner going out of office is required",
      mustNotBeBackingUp: "The Care Partner going OOO must not be serving as a backup at the same time",
      mustNotBeSameExpert: "The Care Partner out of office and back up Care Partner must be different",
      mustNotHaveOverlappingPeriod: "Out of office period must not have overlapping periods"
    },
    startTime: {
      blank: "Start time is required"
    }
  },
  packageGrant: {
    hours: {
      blank: "A number of hours is required",
      number: "The hours to grant must be a number",
      tooSmall: "The hours to grant must be > 0"
    }
  },
  passwordUpdate: {
    base: {
      invalidToken: "That didn't work. Try resetting your password again",
      missingToken: "That didn't work. Try resetting your password again",
      tokenAlreadyUsed: "That link has already been used. Try resetting your password again.",
      tokenExpired: "That didn't work. Try resetting your password again"
    },
    password: {
      blank: "A password is required",
      weak: "Please enter a stronger password"
    },
    passwordConfirmation: {
      mismatch: "Please enter matching passwords"
    }
  },
  peerSupportRequest: {
    consentToContact: "Consent to contact is required",
    email: "Contact information is required",
    message: "A message is required"
  },
  phoneNumber: {
    base: {
      failed: "Something went wrong, you likely reached the maximum amount of retries. Please try again later.",
      formattedNumberRequired: "Something went wrong, please contact support@withgrayce.com",
      noActiveOrPassedInNumber: "Something went wrong, have you entered a phone number?",
      noPhoneNumber: "This phone number does not exist"
    },
    number: {
      blank: "A phone number is required",
      number: "Sorry, that is not a valid phone number",
      taken: "Sorry, that phone number is already in use"
    }
  },
  promo: {
    content: {
      blank: "Content is required"
    },
    headline: {
      blank: "A headline is required",
      tooLong: "The maximum length for the headline is 200 characters"
    },
    photoBlobId: {
      blank: "An image is required.",
      invalid: "Please select a jpg, gif, png or tiff file"
    }
  },
  provider: {
    kind: {
      blank: "A provider specialty is required"
    },
    name: {
      blank: "A name is required"
    }
  },
  refreshPeriodCredit: {
    employees: {
      blank: "Seats is required",
      number: "Seats must be a number",
      tooSmall: "Seats must be greater than zero"
    }
  },
  registration: {
    dateOfBirth: {
      blank: "A date of birth is required",
      correctFormat: "Please use this format: mm/dd/yyyy",
      invalid: "Please pick a valid date of birth",
      tooLarge: "Please pick a valid date of birth",
      tooSmall: "Grayce requires users to be at least 18 years old. Please pick a valid date of birth."
    },
    disclosureAgreement: {
      notAccepted: "Please accept the disclosure agreement"
    },
    email: {
      blank: "An email is required",
      email: "Needs to be an email address",
      notAnEmail: "Needs to be an email address",
      same: "Can't be the same as your work email",
      taken: "Sorry, that email is already in use. Did you mean to sign in?"
    },
    employer: {
      blank: "An employer is required"
    },
    enrollmentCode: {
      invalid: "Sorry, that enrollment code doesn't match any in our system. Did you enter it correctly?"
    },
    enteredEmployer: {
      blank: "An employer is required"
    },
    firstName: {
      blank: "A first name is required"
    },
    lastName: {
      blank: "A last name is required"
    },
    password: {
      blank: "A password is required",
      invalid: "That password is not strong enough. See the requirements above.",
      tooLong: "That password is too long. Please enter a password with fewer than 72 characters.",
      weak: "Please enter a stronger password"
    },
    passwordConfirmation: {
      blank: "Please enter matching passwords",
      mismatch: "Please enter matching passwords"
    },
    relationship: {
      blank: "A relationship is required"
    },
    termsAndConditions: {
      inclusion: "Please accept the terms and conditions",
      notAccepted: "Please accept the terms and conditions"
    },
    timezone: {
      blank: "A timezone is required"
    },
    workEmail: {
      blank: "A work email is required",
      email: "Needs to be an email address",
      invalid: "Needs to be an email address",
      notAnEmail: "Needs to be an email address",
      same: "Can't be the same as your personal email",
      sameAsPersonalEmail: "Can't be the same as your personal email",
      taken: "Sorry, that email is already in use"
    }
  },
  resource: {
    actor: {
      cannotUpdateGoalUntilEditModeComplete: "Your Care Partner is working on this goal"
    },
    base: {
      resourceIdsIncorrect: "The resources being reordered are not all present, try refreshing"
    },
    description: { blank: "A description is required" },
    focusArea: { blank: "A focus area is required" },
    linkTitle: {
      blank: "A link title is required if the url is present"
    },
    linkUrl: {
      blank: "A link url is required if the title is present",
      formatting: "Link must be a valid URL"
    },
    phoneNumber: {
      invalid: "The phone number format is invalid",
      validPhoneNumber: "Please use one of the formats below"
    },
    resource: {
      blank: "There is no resource to delete"
    },
    taskId: {
      blank: "A Care Partner task is required"
    },
    title: { blank: "A name is required" }
  },
  resourceFeature: {
    kind: {
      blank: "A type of field is required"
    },
    name: {
      blank: "A name is required"
    }
  },
  resourceFeatureTemplate: {
    base: {
      noResourceFeatures: "At least one feature is required"
    },
    title: {
      blank: "A title is required"
    }
  },
  richTextArea: {
    linkText: {
      blank: "Text for the link is required"
    },
    linkUrl: {
      blank: "A URL is required",
      formatting: "Link must be a valid URL (includes http or https)"
    }
  },
  signInWithApple: {
    emailTaken:
      "Sorry, the email address you're using to sign in with Apple is already connected to a Grayce account. You'll need to log in with your email and password or with Google.",
    generalError:
      "Sorry, that didn't work. You may need to log in with your email and password or with Google. If you continue to have problems, contact support@withgrayce.com for help."
  },
  signInWithGoogle: {
    generalError:
      "Sorry, that didn't work. You may need to log in with your email and password. If you continue to have problems, contact support@withgrayce.com for help."
  },
  signedDocument: {
    signedDocument: "Must provide a signed document to revoke"
  },
  signedDocumentFax: {
    documentFor: {
      blank: "A consent form recipient is required"
    },
    faxFor: {
      blank: "A facility name is required"
    },
    faxNumber: {
      blank: "A fax number is required",
      onlyNumbers: "Enter numbers only"
    },
    note: {
      blank: "A Note to facility is required"
    }
  },
  simpleLink: {
    label: {
      blank: "A label is required"
    },
    value: { blank: "A value is required", formatting: "Link must be a valid URL" }
  },
  supportingExperts: {
    expertId: {
      blank: "A Care Partner is required"
    }
  },
  surveyResponse: {
    client: {
      notQualifiedForSurvey: "You are not able to take this survey"
    }
  },
  task: {
    category: {
      inclusion: "Enter a valid task category"
    },
    categoryOption: {
      alreadyExists: "Category already exists",
      blank: "A category is required"
    },
    categoryOther: {
      alreadyExists: "Category already exists",
      blank: "A specified other is required"
    },
    clientId: {
      blank: "A member is required in a journey"
    },
    clientVisible: {
      blank: "Member-visible is required"
    },
    dueTime: {
      blank: "A due date is required",
      mustNotBeInThePast: "Due date must not be in the past"
    },
    expertId: {
      blank: "A Care Partner assignee is required"
    },
    journeyId: {
      blank: "A journey is required in a journey",
      notAssigned: "The Care Partner must be on the journey"
    },
    title: {
      blank: "A title is required"
    }
  },
  taskCancel: {
    base: {
      notClientVisible: "Must be a member-visible task to cancel"
    },
    note: {
      blank: "A cancelation reason is required"
    }
  },
  taskResourceFeatures: {
    kind: {
      blank: "A type is required for the feature"
    },
    name: {
      blank: "The feature must have a name"
    }
  },
  timeLog: {
    activityKindId: {
      blank: "An activity type is required"
    },
    base: {
      notEditable: "This time log entry has been deleted"
    },
    date: {
      alreadySubmitted: "You've already submitted your timesheet for that month, no more time can be logged",
      blank: "A date is required",
      noFuture: "Can't log time in the future",
      tooSmall: "Must be within the last four months"
    },
    journeyId: {
      blank: "Please select a journey"
    },
    minutes: {
      blank: "Please enter a number of minutes",
      number: "Minutes must be a number",
      positive: "Minutes must be positive",
      tooLarge: "Please enter a number of minutes < 100",
      tooSmall: "Please enter a number of minutes > 0"
    },
    notes: {
      blank: "Notes are required"
    },
    supportCategory: {
      blank: "A support catagory is required"
    },
    supportKindId: {
      blank: "A support kind is required"
    }
  },
  tracker: {
    status: {
      inclusion: "Must be a valid tracker status",
      invalidStatus: "This tracker status is invalid",
      sameStatus: "This tracker status is already correct"
    },
    type: {
      inclusion: "Must be a valid tracker type"
    }
  },
  urlErrorFallback: "Sorry, something has gone wrong with that url",
  webinarRegistration: {
    webinarId: {
      mustAcceptRegistrations: "Sorry, this event is no longer open for registration"
    }
  },
  workEmailConfirmationSend: {
    base: {
      recentlySent: "A verification email was recently sent to {email}"
    }
  },
  worksheet: {
    actor: {
      cannotUpdateGoalUntilEditModeComplete: "Your Care Partner is working on this goal"
    },
    base: {
      mustBeOneModificationPerItem: "Something went wrong"
    },
    completed: {
      inclusion: "Worksheet item must be completed or uncompleted"
    },
    description: {
      blank: "A description is required"
    },
    title: {
      blank: "A title is required"
    }
  },
  worksheetItem: {
    actor: {
      cannotUpdateGoalUntilEditModeComplete: "Your Care Partner is working on this goal"
    },
    completed: {
      inclusion: "Worksheet item must be completed or uncompleted"
    }
  },
  worksheetTemplate: {
    description: {
      blank: "A description is required"
    },
    focusAreas: {
      blank: "At least one focus area is required",
      invalid: "Must enter valid focus areas"
    },
    title: {
      blank: "A title is required"
    }
  },
  worksheetTemplateItem: {
    acceptsCheckbox: {
      blank: "Accepts checkbox is required"
    },
    acceptsDetails: {
      blank: "Accepts details is required"
    },
    base: {
      itemIdsIncorrect: "The worksheet items being reordered are not all present, try refreshing"
    },
    title: {
      blank: "A title is required"
    }
  }
};

export default errors;
/* eslint-enable max-lines */
