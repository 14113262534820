type GenericNestedMap<T> = Record<string, string | T>;
interface NestedMap extends GenericNestedMap<NestedMap> {}

export const flatten = (inputMap: NestedMap, parentKey = ""): Record<string, string> => {
  let result: Record<string, string> = {};

  for (const key in inputMap) {
    const value = inputMap[key];

    const newKey = parentKey ? `${parentKey}.${key}` : key;

    if (typeof value === "string") {
      result[newKey] = value;
    } else if (typeof value === "object") {
      const nestedResult = flatten(value, newKey);
      result = { ...result, ...nestedResult };
    }
  }

  return result;
};
