const translations = {
  groups: {
    askAnExpert: "Ask an expert",
    cta: "See all >",
    general: "General",
    peerMatch: "Peer match",
    sayHello: "Say hello!",
    seeAllGroups: "See all groups",
    title: "Groups"
  },
  post: {
    cta: "Open in Gather"
  }
};

export default translations;
