import AccountMenu from "@components/account/AccountMenu.translations";
import ActivateButton from "@components/ActivateButton.translations";
import ActivatePage from "@components/ActivatePage.translations";
import HeroCardCarePlanBody from "@components/cards/HeroCardCarePlanBody.translations";
import HeroCardCarePlanItemsBody from "@components/cards/HeroCardCarePlanItemsBody.translations";
import HeroCardCompletedConsultBody from "@components/cards/HeroCardCompletedConsultBody.translations";
import HeroCardInitialBody from "@components/cards/HeroCardInitialBody.translations";
import HeroCardScheduledConsultBody from "@components/cards/HeroCardScheduledConsultBody.translations";
import HeroCardWorkingOnIt from "@components/cards/HeroCardWorkingOnIt.translations";
import CarePlan from "@components/carePlan/CarePlan.translations";
import Goal from "@components/carePlan/Goal.translations";
import Goals from "@components/carePlan/Goals.translations";
import ClientCommunityPage from "@components/client/ClientCommunityPage.translations";
import ClientVisibleTaskResourceActions from "@components/ClientVisibleTaskResouceActions.translations";
import ClientVisibleTaskResourceUsefulnessButtons from "@components/ClientVisibleTaskResourceUsefulnessButtons.translations";
import Community from "@components/community/Community.translations";
import SearchForm from "@components/forms/SearchForm.translations";
import Home from "@components/home/Home.translations";
import RecommendedArticles from "@components/home/RecommendedArticles.translations";
import UpcomingEvents from "@components/home/UpcomingEvents.translations";
import JourneySelector from "@components/JourneySelector.translations";
import ClientInbox from "@components/messaging/ClientInbox.translations";
import NotificationCenter from "@components/NotificationCenter.translations";
import Providers from "@components/trackers/Providers.translations";

const components = {
  AccountMenu,
  ActivateButton,
  ActivatePage,
  CarePlan,
  ClientCommunityPage,
  ClientInbox,
  ClientVisibleTaskResourceActions,
  ClientVisibleTaskResourceUsefulnessButtons,
  Community,
  Goal,
  Goals,
  HeroCardCarePlanBody,
  HeroCardCarePlanItemsBody,
  HeroCardCompletedConsultBody,
  HeroCardInitialBody,
  HeroCardScheduledConsultBody,
  HeroCardWorkingOnIt,
  Home,
  JourneySelector,
  NotificationCenter,
  Providers,
  RecommendedArticles,
  SearchForm,
  UpcomingEvents
};

export default components;
